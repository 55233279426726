.landing-page nav {
  overflow: hidden;
  padding: 10px 10px 0 10px;
}

.landing-page nav .login {
  float: right;
  font-weight: bold;
}

.landing-page .hero {
  text-align: center;
}

.landing-page .title {
  font-size: 2em;
  font-weight: bold;
  margin: 100px 0 0 0;
}

.landing-page .subtitle {
  font-size: 1.25em;
  font-weight: 100;
  margin: 0 auto;
  max-width: 350px;
}

.landing-page .cta {
  display: block;
  font-weight: bold;
  padding: 50px 25px;
}

.landing-page .device {
  text-align: center;
}

.landing-page .device img {
  max-width: 300px;
}

.landing-page .app-store {
  margin: 0 auto 50px auto;
}

.landing-page .app-store img {
  display: block;
  height: 40px;
}

.landing-page .support {
  margin: 0 0 50px 0;
  text-align: center;
}

.landing-page .support a {
  color: #674B7C;
}
